import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { IconClose, IconPlus, IconSave } from '../../../../assets/Icons';
import FAQsTable from './FAQsTable';
import { useState } from 'react';

/**
 * @name FAQsComponent
 * @description This component allows users to add, edit, and manage FAQs related to a given property. It enables input of new FAQs, editing existing ones, and displays the list of FAQs in a table. The component manages state for both new and existing FAQs.
 * @param {Object} propertyInfo - The property information object, which contains the FAQs data.
 * @param {Function} setPropertyInfo - A function to update the property information, including FAQs.
 * @param {number | null} selectedFAQIndex - The index of the FAQ currently being edited, or null if adding a new FAQ.
 * @param {Function} setSelectedFAQIndex - A function to set the index of the FAQ being edited.
 * @returns {JSX.Element} The FAQsComponent to handle the FAQs for the project.
 * @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.0.0
 */

const newFAQInitialValues = { question: '', answer: '' };

const FAQsComponent = ({
	propertyInfo,
	setPropertyInfo,
	selectedFAQIndex,
	setSelectedFAQIndex,
}) => {
	const [newFAQ, setNewFAQ] = useState(newFAQInitialValues);
	const [updatingFAQ, setUpdatingFAQ] = useState(newFAQInitialValues);

	const handleInputChange = event => {
		const { name, value } = event.target;

		if (selectedFAQIndex !== null) {
			setUpdatingFAQ(prevState => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setNewFAQ(prevState => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const addFAQ = () => {
		if (newFAQ.question && newFAQ.answer) {
			setPropertyInfo(prevState => ({
				...prevState,
				faqs: [...prevState.faqs, newFAQ],
			}));
			setNewFAQ({ question: '', answer: '' });
		}
	};
	const editFAQ = (faq, faqIndex) => {
		setSelectedFAQIndex(faqIndex);
		setUpdatingFAQ(faq);
	};

	const saveEditFAQ = () => {
		if (selectedFAQIndex !== null) {
			setPropertyInfo(prevState => {
				const updatedFAQs = [...prevState.faqs];
				updatedFAQs[selectedFAQIndex] = updatingFAQ;

				return {
					...prevState,
					faqs: updatedFAQs,
				};
			});

			setUpdatingFAQ(newFAQInitialValues);
			setSelectedFAQIndex(null);
		}
	};

	const cancelEdit = () => {
		setUpdatingFAQ(newFAQInitialValues);
		setSelectedFAQIndex(null);
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Question</FormLabel>
				<Input
					placeholder='Add question'
					name='question'
					value={
						selectedFAQIndex !== null
							? updatingFAQ.question
							: newFAQ.question
					}
					onChange={handleInputChange}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Response</FormLabel>
				<Textarea
					rows={5}
					placeholder='Add answer'
					name='answer'
					value={
						selectedFAQIndex !== null
							? updatingFAQ.answer
							: newFAQ.answer
					}
					onChange={handleInputChange}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				{selectedFAQIndex !== null ? (
					<Flex gap='10px'>
						<Button
							variant='secondary'
							gap='5px'
							onClick={cancelEdit}
						>
							<IconClose boxSize='13px' />
							<Text fontSize='13px'>Cancel</Text>
						</Button>
						<Button variant='main' gap='5px' onClick={saveEditFAQ}>
							<IconSave boxSize='13px' />
							<Text fontSize='13px'>Save edit</Text>
						</Button>
					</Flex>
				) : (
					<Button variant='secondary' gap='5px' onClick={addFAQ}>
						<IconPlus boxSize='13px' mt='-2px' />
						<Text fontSize='13px'>Add question</Text>
					</Button>
				)}
			</Flex>
			{propertyInfo.faqs.length > 0 && (
				<Box gridColumn='span 12'>
					<FAQsTable
						propertyInfo={propertyInfo}
						setPropertyInfo={setPropertyInfo}
						editFAQ={editFAQ}
					/>
				</Box>
			)}
		</Grid>
	);
};

export default FAQsComponent;
