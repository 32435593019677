import { Box, Flex } from '@chakra-ui/react';
import { IconClose } from '../../../../assets/Icons';

const MediaComponent = ({ url, onRemove }) => {
	return (
		<Flex
			position='relative'
			boxSize='100px'
			borderRadius='5px'
			backgroundImage={`url(${url})`}
			backgroundSize='cover'
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
			justify='end'
		>
			<Box
				as='video'
				src={url}
				width='100%'
				height='100%'
				borderRadius='5px'
				objectFit='cover'
				muted
			/>
			<Flex
				position='absolute'
				top='5px'
				right='5px'
				backgroundColor='transparencyWhite.800'
				boxSize='15px'
				borderRadius='50%'
				align='center'
				justify='center'
				cursor='pointer'
				onClick={onRemove}
			>
				<IconClose boxSize='10px' color='transparencyBlack.800' />
			</Flex>
		</Flex>
	);
};

export default MediaComponent;
