import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import {
	formatOrDash,
	multiplicationCalc,
	sumCalc,
} from '../../../../../utils/utils';
import { useEffect } from 'react';

const ProjectCostSummaryBoxInfo = ({
	propertyInfo,
	selectedCurrency,
	setTotalCosts,
}) => {
	const {
		taxesAndOperatingCosts,
		currentPropertyValue,
		renovationAndFitoutsCosts,
	} = propertyInfo.data;

	const costsSum = sumCalc(
		taxesAndOperatingCosts,
		currentPropertyValue,
		renovationAndFitoutsCosts,
	);
	const elevexFee = multiplicationCalc(costsSum, 0.03);
	const total = sumCalc(costsSum, elevexFee);

	useEffect(() => {
		setTotalCosts(total);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [total]);

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				Project Cost Summary
			</Text>
			<Stack py='9px' gap='9px'>
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Current Property Value
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(currentPropertyValue, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Taxes and Operating Costs
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(taxesAndOperatingCosts, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Renovation and Fitouts Costs
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(
							renovationAndFitoutsCosts,
							selectedCurrency,
						)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Elevex Fee
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(elevexFee, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Total
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(total, selectedCurrency)}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default ProjectCostSummaryBoxInfo;
