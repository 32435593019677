import { Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { currencyFormatter } from '../../../utils/utils';
import { IconWallet } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import { interactWithTransak } from '../../../lib/transak';

const AccountBalanceComponent = ({
	hiddenInfo,
	balance,
	colorMode,
	color,
	isMobile,
}) => {
	const { t } = useTranslation();
	const hiddenInfoString = '*****';

	return (
		<Flex
			minW={isMobile ? '312px' : '530px'}
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.950'
			}
			justifyContent='center'
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			p='20px'
			h='100%'
		>
			<Stack textAlign='center' alignItems='center' gap='20px' my='auto'>
				<Text
					variant='colored'
					fontSize='24px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					{t('walletPage.accountBalance')}
				</Text>
				<Stack gap='5px' mb='5px'>
					<Text fontSize='10px' variant='gray' letterSpacing={-0.02}>
						{t('walletPage.totalAmountFIAT')}
					</Text>
					<Stack>
						{!balance ? (
							<Skeleton h='30px' mb='20px' />
						) : (
							<Text
								fontWeight='700'
								fontSize='24px'
								letterSpacing={-0.02}
								lineHeight='20px'
								p='5px'
								mb='20px'
							>
								{hiddenInfo
									? hiddenInfoString
									: currencyFormatter(balance)}
							</Text>
						)}
					</Stack>
				</Stack>
				<Stack
					gap='5px'
					cursor={'pointer'}
					onClick={() =>
						interactWithTransak({ purchaseQuantity: 1000 })
					}
				>
					<Stack
						borderRadius='50px'
						bg={color}
						w='40px'
						h='40px'
						p='10px'
						alignItems='center'
						justifyContent='center'
						_hover={{ transform: 'scale(1.1)' }}
						_active={{ transform: 'scale(1)' }}
					>
						<IconWallet
							color={colorMode === 'dark' ? 'black' : 'white'}
							boxSize='17px'
						/>
					</Stack>
					<Text fontWeight='400' fontSize='10px'>
						{t('walletPage.topUp')}
					</Text>
				</Stack>
			</Stack>
		</Flex>
	);
};

export default AccountBalanceComponent;
