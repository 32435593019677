import { Stack } from '@chakra-ui/react';
import FinancialDetailsBoxInfo from './FinancialDetailsBoxInfo';
import ProjectCostSummaryBoxInfo from './ProjectCostSummaryBoxInfo';
import RentalIncomeBoxInfo from './RentalIncomeBoxInfo';
import CapitalGrowthBoxInfo from './CapitalGrowthBoxInfo';
import { useState } from 'react';

const InfoBoxesComponent = ({ propertyInfo, selectedCurrency }) => {
	const [totalCosts, setTotalCosts] = useState('-');
	const [estimatedTotalNetProfit, setEstimatedTotalNetProfit] = useState('-');
	const [totalProjectFundraise, setTotalProjectFundraise] = useState('-');

	return (
		<Stack w='403px' gap='25px'>
			<FinancialDetailsBoxInfo
				propertyInfo={propertyInfo}
				selectedCurrency={selectedCurrency}
				totalCosts={totalCosts}
				setEstimatedTotalNetProfit={setEstimatedTotalNetProfit}
				setTotalProjectFundraise={setTotalProjectFundraise}
			/>
			<ProjectCostSummaryBoxInfo
				propertyInfo={propertyInfo}
				selectedCurrency={selectedCurrency}
				setTotalCosts={setTotalCosts}
			/>
			<RentalIncomeBoxInfo
				propertyInfo={propertyInfo}
				selectedCurrency={selectedCurrency}
				totalProjectFundraise={totalProjectFundraise}
			/>
			<CapitalGrowthBoxInfo
				propertyInfo={propertyInfo}
				selectedCurrency={selectedCurrency}
				totalCosts={totalCosts}
				estimatedTotalNetProfit={estimatedTotalNetProfit}
				totalProjectFundraise={totalProjectFundraise}
			/>
		</Stack>
	);
};

export default InfoBoxesComponent;
