export const EmptyUserState = {
	userId: '',
	avatarImage: '',
	fullname: '',
	username: '',
	email: '',
	walletAddress: '',
	role: '',
	language: '',
	appearance: '',
	phoneNumber: '',
	city: '',
	country: '',
	currency: '',
	isFirstTime: '',
	ethBalance: '',
	usdcBalance: '',
	usdtBalance: '',
	usdBalance: '',
};
