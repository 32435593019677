import { Contract, JsonRpcProvider } from 'ethers';
import { usdcABI } from '../interfaces/usdcABI';
import { formatBalanceWallet, oracle } from './walletService';
import { getTokenAmount } from '../utils/utils';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);
const contractAddress = process.env.REACT_APP_USDC_CONTRACT_ADDRESS;
export const usdcContract = new Contract(contractAddress, usdcABI, provider);

export const getUSDCBalance = async walletAddress => {
	const balance = await usdcContract.balanceOf(walletAddress);
	return getTokenAmount(balance, 6);
};

export const getUSDCValueinUSD = async () => {
	const price = await oracle.getLatestPrice('USDC');
	return getTokenAmount(price, 6);
};

export const covertUSDCinUSD = async usdcAmount => {
	const usdcAmountNumber = parseFloat(usdcAmount);
	const usdcPrice = await getUSDCValueinUSD();
	return usdcAmountNumber * usdcPrice;
};

export const formatBalanceUSDC = async usdcAmount => {
	const formattedUSDCBalance = usdcAmount.toFixed(3).toString();
	return formatBalanceWallet(formattedUSDCBalance);
};
