/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Tab, Text, useColorMode, useToast } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { showToast } from '../../../utils/Toasts';

/**
*
    @name TabComponent
    @description Contains the logic to disable each tab and to display the error toast.
    @returns {JS.Element} TabComponent.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TabComponent = ({
	title,
	icon,
	selectedTableIndex,
	activeTab,
	indexTab,
}) => {
	const toast = useToast();
	const tabRef = useRef(null);
	const { colorMode } = useColorMode();
	const [isDisabled, setIsDisabled] = useState(
		selectedTableIndex !== null && activeTab !== indexTab,
	);

	useEffect(() => {
		setIsDisabled(selectedTableIndex !== null && activeTab !== indexTab);
	}, [selectedTableIndex]);

	return (
		<Box position='relative'>
			<Tab
				ref={tabRef}
				isDisabled={isDisabled}
				maxH='28px'
				fontWeight='400'
				borderRadius='5px'
				gap='5px'
				p='10px 7px'
				_selected={{ fontWeight: '500', bg: 'transparencyWhite.100' }}
				_disabled={{
					opacity: 0.5,
				}}
			>
				{icon}
				<Text
					fontSize='12px'
					lineHeight='20px'
					color={colorMode === 'dark' ? 'white' : 'black'}
				>
					{title}
				</Text>
			</Tab>
			{isDisabled && activeTab !== indexTab && (
				<Box
					top='0'
					left='0'
					w={`${tabRef?.current.offsetWidth}px`}
					h={`${tabRef?.current.offsetHeight}px`}
					position='absolute'
					cursor='pointer'
					onClick={() => showToast(toast)}
				></Box>
			)}
		</Box>
	);
};

export default TabComponent;
