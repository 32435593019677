/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import {
	formatOrDash,
	formatNumber,
	multiplicationCalc,
	subtractCalc,
	estimatedTotalNetProfitCalc,
} from '../../../../../utils/utils';
import { useEffect } from 'react';

const FinancialDetailsBoxInfo = ({
	propertyInfo,
	selectedCurrency,
	totalCosts,
	setEstimatedTotalNetProfit,
	setTotalProjectFundraise,
}) => {
	const {
		pricePerToken,
		quantityOfTokens,
		estimatedResalePrice,
		rentalTimeline,
		estimatedAnnualIncomeFromRental,
	} = propertyInfo.data;

	const totalProjectFundraise = multiplicationCalc(
		pricePerToken,
		quantityOfTokens,
	);
	const netCapitalGainOnSale = subtractCalc(estimatedResalePrice, totalCosts);
	const estimatedTotalNetProfit = estimatedTotalNetProfitCalc(
		estimatedAnnualIncomeFromRental,
		rentalTimeline,
		netCapitalGainOnSale,
	);

	useEffect(() => {
		setEstimatedTotalNetProfit(estimatedTotalNetProfit);
	}, [estimatedTotalNetProfit]);

	useEffect(() => {
		setTotalProjectFundraise(totalProjectFundraise);
	}, [totalProjectFundraise]);

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				Financial Details
			</Text>
			<Stack py='9px' gap='9px'>
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Price per token
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(pricePerToken, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Quantity of tokens
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(quantityOfTokens) !== 'NaN'
							? formatNumber(quantityOfTokens)
							: '-'}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Total Project Fundraise
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(totalProjectFundraise, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Net Capital Gain on Sale
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(netCapitalGainOnSale, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Estimated Total Net Profit
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(
							estimatedTotalNetProfit,
							selectedCurrency,
						)}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default FinancialDetailsBoxInfo;
