import { useTranslation } from 'react-i18next';
import {
	Button,
	Flex,
	FormLabel,
	Grid,
	Input,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import {
	IconArrowDown,
	IconCheck,
	IconEdit,
	IconPDF,
	IconTrash,
} from '../../../../assets/Icons';
import { useState } from 'react';
import { documentLanguageOptions } from '../../../../data/optionsData';
import MenuSelectComponent from '../../../../components/MenuSelectComponent';

const AddDocumentsComponent = () => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [uploadedDocuments, setUploadedDocuments] = useState([]);

	const [selectedDocument, setSelectedDocument] = useState(undefined);

	const [selectedLanguage, setSelectedLanguage] = useState(
		documentLanguageOptions[0],
	);

	const [prueba, setPrueba] = useState();

	const handleDrop = event => {
		event.preventDefault();
		event.stopPropagation();

		setSelectedDocument(event.dataTransfer.files[0]);
	};

	const handleDragOver = event => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleClick = () => {
		document.getElementById('documentInput').click();
	};

	const addDocument = event => {
		const files = Array.from(event.target.files);
		setSelectedDocument(files[0]);

		setPrueba(URL.createObjectURL(files[0]));
	};

	const confirmAddDocument = () => {
		if (selectedDocument) {
			const documentWithFields = {
				file: selectedDocument,

				edit: false,
			};

			setUploadedDocuments(prevDocs => [...prevDocs, documentWithFields]);
			setSelectedDocument(null);
		}
	};

	const removeDocument = index => {
		setUploadedDocuments(prevDocs =>
			prevDocs.filter((_, i) => i !== index),
		);
	};
	const editModeOn = index => {
		setUploadedDocuments(prevDocs =>
			prevDocs.map((doc, i) => {
				if (i === index) {
					return {
						...doc,
						edit: true,
					};
				}
				return doc;
			}),
		);
	};

	const saveDocumentChanges = index => {
		setUploadedDocuments(prevDocs =>
			prevDocs.map((doc, i) => {
				if (i === index) {
					return {
						...doc,
						language: selectedLanguage,
						edit: false,
					};
				}
				return doc;
			}),
		);
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='10px'>
			<Stack gridColumn={selectedDocument ? 'span 8' : 'span 12'}>
				<FormLabel variant='propertyLabel' m='0'>
					Attach documents
				</FormLabel>
				<Flex
					h='90px'
					bg='transparencyWhite.100'
					border='0.5px dashed'
					borderColor={
						colorMode === 'dark' ? 'brand.200' : 'brand.500'
					}
					borderRadius='12px'
					alignItems='center'
					px='30px'
					gap='10px'
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onClick={handleClick}
					cursor='pointer'
				>
					<Input
						id='documentInput'
						type='file'
						multiple
						accept='.pdf,.doc,.docx,.xlsx,.txt'
						style={{ display: 'none' }}
						onChange={addDocument}
					/>
					<IconPDF />
					{selectedDocument ? (
						<Text fontSize='12px' fontWeight='400'>
							{selectedDocument.name}
						</Text>
					) : (
						<Text variant='gray' fontSize='12px' fontWeight='400'>
							Drop documents or click to upload
						</Text>
					)}
				</Flex>
			</Stack>
			{selectedDocument && (
				<Stack gridColumn='span 4'>
					<FormLabel variant='propertyLabel' m='0'>
						Language
					</FormLabel>
					<MenuSelectComponent
						options={documentLanguageOptions}
						fieldName='language'
						tabInfo={selectedDocument}
						setTabInfo={setSelectedDocument}
					/>
					<Button variant='main' onClick={() => confirmAddDocument()}>
						Add
					</Button>
				</Stack>
			)}
			{uploadedDocuments.map((doc, index) => (
				<Flex
					gridColumn='span 12'
					key={index}
					h='42px'
					bg='transparencyWhite.100'
					border='1px solid'
					borderColor='transparencyWhite.100'
					borderRadius='12px'
					justifyContent='space-between'
					alignItems='center'
					px='20px'
				>
					<Link href={prueba} isExternal>
						<Flex gap='10px' align='center'>
							<IconPDF />
							<Text fontSize='12px' fontWeight='400'>
								{doc.file.name}
							</Text>
						</Flex>
					</Link>
					<Flex align='center' gap='10px'>
						{!doc.edit ? (
							<Text fontSize='14px'>
								{/* {t(
									`addProperty.financialDetailsTab.addDocuments.language.${doc.language}`,
								)} */}
								{doc.language}
							</Text>
						) : (
							<Menu>
								<MenuButton
									h='42px'
									fontWeight='300'
									borderRadius='5px'
									textAlign='start'
									bg='none'
									mr='-10px'
									_hover={{
										bg: 'none',
									}}
									_active={{
										bg: 'none',
									}}
									rightIcon={<IconArrowDown boxSize='12px' />}
									as={Button}
								>
									<Text fontSize='14px' fontWeight='400'>
										{t(
											`addProperty.financialDetailsTab.addDocuments.language.${selectedLanguage}`,
										)}
									</Text>
								</MenuButton>
								<MenuList fontSize='12px' zIndex={10}>
									{documentLanguageOptions.map((elem, i) => (
										<MenuItem
											key={i}
											value={elem}
											onClick={() =>
												setSelectedLanguage(elem)
											}
										>
											{elem}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						)}
						{!doc.edit ? (
							<IconEdit
								cursor='pointer'
								onClick={() => editModeOn(index)}
							/>
						) : (
							<IconCheck
								cursor='pointer'
								color={
									colorMode === 'dark'
										? 'brand.200'
										: 'brand.500'
								}
								onClick={() => saveDocumentChanges(index)}
							/>
						)}
						<IconTrash
							cursor='pointer'
							onClick={() => removeDocument(index)}
						/>
					</Flex>
				</Flex>
			))}
		</Grid>
	);
};
export default AddDocumentsComponent;
