import {
	Button,
	Flex,
	FormLabel,
	Input,
	InputGroup,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import ImageComponent from './MediaComponent';
import { useState } from 'react';
import { IconArrowDown } from '../../../../assets/Icons';

const GalleryComponent = ({ propertyInfo, setPropertyInfo }) => {
	const { colorMode } = useColorMode();
	const [featuredImage, setFeaturedImage] = useState();

	const handleDrop = event => {
		event.preventDefault();
		event.stopPropagation();

		const files = Array.from(event.dataTransfer.files);

		const newMedia = files.map(file => ({
			file,
			url: URL.createObjectURL(file),
		}));

		setPropertyInfo(prevInfo => ({
			...prevInfo,
			gallery: [...prevInfo.gallery, ...newMedia],
		}));
	};

	const handleDragOver = event => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleClick = () => {
		document.getElementById('fileInput').click();
	};

	const addMediaFile = event => {
		const files = Array.from(event.target.files);
		const newMedia = files.map(file => ({
			file,
			url: URL.createObjectURL(file),
		}));

		setPropertyInfo(prevInfo => ({
			...prevInfo,
			gallery: [...prevInfo.gallery, ...newMedia],
		}));
	};

	const removeImage = url => {
		setPropertyInfo(prevInfo => {
			const updatedGallery = prevInfo.gallery.filter(
				media => media.url !== url,
			);
			updatedGallery.forEach(media => URL.revokeObjectURL(media.url));
			return {
				...prevInfo,
				gallery: updatedGallery,
			};
		});
	};

	return (
		<Stack gap='20px'>
			<InputGroup display='flex' flexDir='column'>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Add images
					</Text>
				</FormLabel>
				<Flex
					role='button'
					h='100px'
					alignItems='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.100'
					}
					border='1px dashed'
					borderColor={
						colorMode === 'dark' ? 'brand.200' : 'brand.500'
					}
					borderRadius='12px'
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onClick={handleClick}
					cursor='pointer'
					p='10px'
				>
					<Input
						id='fileInput'
						type='file'
						multiple
						accept='image/*,video/*'
						style={{ display: 'none' }}
						onChange={addMediaFile}
					/>
					<Stack gap='4px'>
						<Text
							fontSize='12px'
							fontWeight='700'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							Drag and drop images or videos here or click to
							upload
						</Text>
						<Text
							fontSize='12px'
							fontWeight='400'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							Recommendation: For best viewing, use images with a
							minimun size of 1080x920 pixels.
						</Text>
					</Stack>
				</Flex>
			</InputGroup>
			<Flex wrap='wrap' gap='15.55px'>
				{propertyInfo.gallery.map((media, index) => (
					<ImageComponent
						key={index}
						url={media.url}
						onRemove={() => removeImage(media.url)}
					/>
				))}
			</Flex>
			<InputGroup display='flex' flexDir='column' zIndex={100}>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Cover Image
					</Text>
				</FormLabel>
				<Menu>
					<MenuButton
						h='40px'
						w='100%'
						fontWeight='400'
						borderRadius='5px'
						textAlign='start'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.100'
						}
						_hover={{
							bg:
								colorMode === 'dark'
									? 'transparencyWhite.200'
									: 'transparencyBlack.200',
						}}
						_active={{
							border: '0.5px solid',
							borderColor:
								colorMode === 'dark'
									? 'brand.200'
									: 'brand.500',
						}}
						rightIcon={<IconArrowDown boxSize='12px' />}
						as={Button}
						isDisabled={propertyInfo.gallery.length === 0}
					>
						<Text
							fontSize='12px'
							fontWeight='400'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							{featuredImage
								? featuredImage.file.name
								: 'Upload an image'}
						</Text>
					</MenuButton>
					<MenuList fontSize='12px' zIndex={10}>
						{propertyInfo.gallery.map((elem, i) => (
							<MenuItem
								key={i}
								value={elem.file.name}
								onClick={() => setFeaturedImage(elem)}
							>
								{elem.file.name}
							</MenuItem>
						))}
					</MenuList>
				</Menu>
			</InputGroup>

			{featuredImage && (
				<ImageComponent
					url={featuredImage.url}
					onRemove={() => setFeaturedImage('')}
				/>
			)}

			<InputGroup display='flex' flexDir='column'>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Featured Video
					</Text>
				</FormLabel>
				<Input />
			</InputGroup>
		</Stack>
	);
};

export default GalleryComponent;
