import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import {
	annualInvestmentReturnCalc,
	formatNumber,
	formatOrDash,
	monthsSubtract,
	percentageCalc,
	subtractCalc,
} from '../../../../../utils/utils';

const CapitalGrowthBoxInfo = ({
	propertyInfo,
	selectedCurrency,
	totalCosts,
	estimatedTotalNetProfit,
	totalProjectFundraise,
}) => {
	const {
		estimatedResalePrice,
		appreciation,
		projectStart,
		projectEnd,
		purchasedOrPledge,
	} = propertyInfo.data;

	const startDate =
		purchasedOrPledge === 'Purchased'
			? projectStart?.from
			: projectStart?.day;

	const endDate = projectEnd?.to;
	const monthsNumber = monthsSubtract(startDate, endDate);

	const capitalGainOnSale = subtractCalc(estimatedResalePrice, totalCosts);
	const estimatedTotalROI = percentageCalc(
		estimatedTotalNetProfit,
		totalProjectFundraise,
	);
	const estimatedTotalROIFormatted = estimatedTotalROI.toFixed(2);

	const annualInvestmentReturn = annualInvestmentReturnCalc(
		estimatedTotalROI,
		monthsNumber,
	).toFixed(2);

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				Capital Growth and Estimated Returns
			</Text>
			<Stack py='9px' gap='9px'>
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Estimated Resale Price
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(estimatedResalePrice, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Appreciation
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{!isNaN(formatNumber(appreciation))
							? `${formatNumber(appreciation)} %`
							: '-'}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Capital Gain on Sale
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(capitalGainOnSale, selectedCurrency)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Estimated Total ROI
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(estimatedTotalROIFormatted) !== 'NaN'
							? `${formatNumber(estimatedTotalROIFormatted)} %`
							: '-'}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Annual Investment Return
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(annualInvestmentReturn) !== 'NaN'
							? `${formatNumber(annualInvestmentReturn)} %`
							: '-'}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default CapitalGrowthBoxInfo;
