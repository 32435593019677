import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import {
	formatNumber,
	formatOrDash,
	percentageCalc,
} from '../../../../../utils/utils';

const RentalIncomeBoxInfo = ({
	propertyInfo,
	selectedCurrency,
	totalProjectFundraise,
}) => {
	const {
		rentalTimeline,
		estimatedAnnualIncomeFromRental,
		distributionFrequency,
		category,
	} = propertyInfo.data;

	const estimatedMonthlyPayment = estimatedAnnualIncomeFromRental / 12;
	const annualRentalYield = percentageCalc(
		estimatedAnnualIncomeFromRental,
		totalProjectFundraise,
	).toFixed(2);

	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				Rental Income Overview
			</Text>
			<Stack py='9px' gap='9px'>
				{category === 'Rental' && (
					<>
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Estimated Annual Net Income from Rental
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{formatOrDash(
									estimatedAnnualIncomeFromRental,
									selectedCurrency,
								)}
							</Text>
						</Flex>
						<Divider />
					</>
				)}
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Rental Timeline
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{!isNaN(formatNumber(rentalTimeline))
							? `${formatNumber(rentalTimeline)} Month(s)`
							: '-'}
					</Text>
				</Flex>
				{category === 'Rental' && (
					<>
						<Divider />
						<Flex justifyContent='space-between'>
							<Text
								variant='gray'
								fontWeight='400'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Frequency of Distribution
							</Text>
							<Text
								variant='gray'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{distributionFrequency}
							</Text>
						</Flex>
						<Divider />
						<Flex justifyContent='space-between'>
							<Text
								fontWeight='700'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Estimated Monthly Payment
							</Text>
							<Text
								fontWeight='700'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{formatOrDash(
									estimatedMonthlyPayment,
									selectedCurrency,
								)}
							</Text>
						</Flex>
						<Divider />
						<Flex justifyContent='space-between'>
							<Text
								fontWeight='700'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								Annual Rental Yield
							</Text>
							<Text
								fontWeight='700'
								fontSize='12px'
								lineHeight='20px'
								letterSpacing={-0.02}
							>
								{formatNumber(annualRentalYield) !== 'NaN'
									? `${formatNumber(annualRentalYield)} %`
									: '-'}
							</Text>
						</Flex>
					</>
				)}
			</Stack>
		</Stack>
	);
};

export default RentalIncomeBoxInfo;
