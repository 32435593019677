import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';
import TimelineTable from './TimelineTable';
import DatePickerTimelineComponent from '../../../../components/DatePickerComponent/DatePickerTimelineComponent';
import { IconClose, IconPlus, IconSave } from '../../../../assets/Icons';
import { useState } from 'react';
import { formatDateLongMonth } from '../../../../utils/utils';

/**
 * @name TimelineComponent
 * @description This component allows users to add, edit, and manage timeline steps for a given property. Each step contains a title, description, start date, and end date. Users can input new steps, edit existing ones, and view the timeline in a table format. The component manages state for creating and editing steps.
 * @param {Object} propertyInfo - The property information object, which includes the timeline data.
 * @param {Function} setPropertyInfo - A function to update the property information, including timeline steps.
 * @param {number | null} selectedStepIndex - The index of the step currently being edited, or null if adding a new step.
 * @param {Function} setSelectedStepIndex - A function to set the index of the step being edited.
 * @returns {JSX.Element} The TimelineComponent to handle the project's timeline steps.
 * @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.0.0
 */

const newStepInitialValues = {
	title: '',
	description: '',
	startDate: null,
	endDate: null,
};

const TimelineComponent = ({
	propertyInfo,
	setPropertyInfo,
	selectedStepIndex,
	setSelectedStepIndex,
}) => {
	const [newStep, setNewStep] = useState(newStepInitialValues);
	const [editingStep, setEditingStep] = useState(newStepInitialValues);

	const handleInputChange = event => {
		const { name, value } = event.target;
		if (selectedStepIndex !== null) {
			setEditingStep(prevState => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setNewStep(prevState => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const addStep = () => {
		if (
			newStep.title &&
			newStep.description &&
			newStep.startDate &&
			newStep.endDate
		) {
			setPropertyInfo(prevState => ({
				...prevState,
				timeline: [...prevState.timeline, newStep],
			}));
			setNewStep(newStepInitialValues);
		}
	};
	const editStep = (step, stepIndex) => {
		setSelectedStepIndex(stepIndex);
		setEditingStep(step);
	};

	const cancelEdit = () => {
		setEditingStep(newStepInitialValues);
		setSelectedStepIndex(null);
	};
	const saveEditStep = () => {
		if (selectedStepIndex !== null) {
			setPropertyInfo(prevState => {
				const updatedTimeline = [...prevState.timeline];
				updatedTimeline[selectedStepIndex] = editingStep;
				return {
					...prevState,
					timeline: updatedTimeline,
				};
			});

			setEditingStep(newStepInitialValues);
			setSelectedStepIndex(null);
		}
	};

	const getFormattedDate = dateKey => {
		const date =
			selectedStepIndex !== null
				? editingStep[dateKey]
				: newStep[dateKey];
		return date ? formatDateLongMonth(date) : '';
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 6'>
				<FormLabel variant='propertyLabel'>Title</FormLabel>
				<Input
					placeholder='Add step title'
					name='title'
					value={
						selectedStepIndex !== null
							? editingStep.title
							: newStep.title
					}
					onChange={handleInputChange}
				/>
			</FormControl>

			<FormControl gridColumn='span 3'>
				<FormLabel variant='propertyLabel'>Start date</FormLabel>

				<DatePickerTimelineComponent
					name='startDate'
					setEditing={setEditingStep}
					setNew={setNewStep}
					selected={
						selectedStepIndex !== null
							? editingStep.startDate
							: newStep.startDate
					}
					selectedIndex={selectedStepIndex}
					formattedDate={getFormattedDate('startDate')}
				/>
			</FormControl>

			<FormControl gridColumn='span 3'>
				<FormLabel variant='propertyLabel'>End date</FormLabel>

				<DatePickerTimelineComponent
					name='endDate'
					setEditing={setEditingStep}
					setNew={setNewStep}
					selectedIndex={selectedStepIndex}
					formattedDate={getFormattedDate('endDate')}
					selected={
						selectedStepIndex !== null
							? editingStep.endDate
							: newStep.endDate
					}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Description</FormLabel>
				<Textarea
					rows={5}
					placeholder='Add step description'
					name='description'
					value={
						selectedStepIndex !== null
							? editingStep.description
							: newStep.description
					}
					onChange={handleInputChange}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				{selectedStepIndex !== null ? (
					<Flex gap='10px'>
						<Button
							variant='secondary'
							gap='5px'
							onClick={cancelEdit}
						>
							<IconClose boxSize='13px' />
							<Text fontSize='13px'>Cancel</Text>
						</Button>
						<Button variant='main' gap='5px' onClick={saveEditStep}>
							<IconSave boxSize='13px' />
							<Text fontSize='13px'>Save edit</Text>
						</Button>
					</Flex>
				) : (
					<Button variant='secondary' gap='5px' onClick={addStep}>
						<IconPlus boxSize='13px' />
						<Text fontSize='13px'>Add step</Text>
					</Button>
				)}
			</Flex>
			{propertyInfo.timeline.length > 0 && (
				<Box gridColumn='span 12'>
					<TimelineTable
						propertyInfo={propertyInfo}
						setPropertyInfo={setPropertyInfo}
						editStep={editStep}
					/>
				</Box>
			)}
		</Grid>
	);
};

export default TimelineComponent;
