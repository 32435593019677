import { Flex, Skeleton, Stack, Text, useColorMode } from '@chakra-ui/react';

const InfoCurrencyBox = ({ icon, currency, amount, hiddenInfo }) => {
	const hiddenInfoString = '*****';
	const { colorMode } = useColorMode();
	return (
		<Flex
			flexDir='column'
			w='92px'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			borderRadius='5px'
			p='5px'
			gap='0px'
		>
			<Flex alignSelf='center' justifyContent='center' gap='5px' p='5px'>
				{icon}
				<Text
					variant='gray'
					fontSize='10px'
					lineHeight='20px'
					letterSpacing={-0.02}
				>
					{currency}
				</Text>
			</Flex>
			<Stack>
				{amount !== undefined ? (
					<Text fontSize='15px' fontWeight='semibold'>
						{hiddenInfo ? hiddenInfoString : amount}
					</Text>
				) : (
					<Skeleton  h='22.5px' />
				)}
			</Stack>
		</Flex>
	);
};

export default InfoCurrencyBox;
