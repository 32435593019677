// import { useSelector } from 'react-redux';
import { Flex, Text, Button, useColorMode } from '@chakra-ui/react';
import { IconMarketplace } from '../../../../../assets/Icons';
import FeeComponent from '../../../../../components/FeeComponent';
import { interactWithTransak } from '../../../../../lib/transak';
import { useSelector } from 'react-redux';

const CreditCardPurchase = () => {
	const { colorMode } = useColorMode();
	const userState = useSelector(store => store.user);
	const { email, walletAddress } = userState;
	return (
		<Flex
			flexDirection='column'
			align='center'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			gap='15px'
			p='20px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='bold'
				lineHeight='150%'
			>
				Purchase summary
			</Text>
			<Flex flexDirection='column' gap='15px'>
				<Text
					variant='colored'
					fontSize='32px'
					fontWeight='bold'
					lineHeight='15px'
				>
					1000€
				</Text>
				<Text fontSize='10px' fontWeight='300'>
					10 Project token
				</Text>
			</Flex>
			<FeeComponent />
			<Button
				variant='main'
				gap='8px'
				onClick={() =>
					interactWithTransak({
						purchaseQuantity: 1000, // TODO: Cambiar con la cantidad que el usuario introduzca por el input.
						userInfo: {
							email,
							walletAddress,
						},
					})
				}
			>
				<IconMarketplace boxSize='16px' />
				Confirm your purchase
			</Button>
		</Flex>
	);
};

export default CreditCardPurchase;
