import {
	Button,
	Flex,
	Stack,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import {
	IconAlert,
	IconBuilding,
	IconClose,
	IconGallery,
	IconHelp,
	IconPublish,
	IconSave,
	IconTimeline,
} from '../../assets/Icons';
import TabComponent from './components/TabComponent';
import DollarIcon from '../../assets/Icons/currency/DollarIcon';
import InformationComponent from './components/InformationComponent/InformationComponent';
import ProjectUpdatesComponent from './components/ProjectUpdatesComponent/ProjectUpdatesComponent';
import TimelineComponent from './components/TimelineComponent/TimelineComponent';
import FAQsComponent from './components/FAQsComponent/FAQsComponent';
import FinancialDetailsComponent from './components/FinancialDetailsComponent/FinancialDetailsComponent';
import GalleryComponent from './components/GalleryComponent/GalleryComponent';
import { useEffect, useState } from 'react';
import { savePlaceDetails } from '../../utils/GoogleMapsInfo';
import { addProperty } from '../../services/propertyService';
import { showToast } from '../../utils/Toasts';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../routes/Routes';

const initialPropertyInfo = {
	data: {},
	faqs: [],
	updates: [],
	timeline: [],
	projectMethodPayments: {
		usdt: true,
		usdc: true,
		eth: true,
	},
	gallery: [],
};

const tabs = [
	{ title: 'Information', icon: IconBuilding },
	{ title: 'Gallery', icon: IconGallery },
	{ title: 'Financial details', icon: DollarIcon },
	{ title: 'Project updates', icon: IconAlert },
	{ title: 'FAQs', icon: IconHelp },
	{ title: 'Timeline', icon: IconTimeline },
];

const AddProperty = () => {
	const { colorMode } = useColorMode();
	const navigate = useNavigate();
	const toast = useToast();
	const iconsColor = colorMode === 'dark' ? 'white' : 'black';
	const [selectedTableIndex, setSelectedTableIndex] = useState(null);
	const [activeTab, setActiveTab] = useState(0);
	const [isDisabled, setIsDisabled] = useState(false);
	const [placeSelected, setPlaceSelected] = useState();
	const [selectedSuggestion, setSelectedSuggestion] = useState(null);
	const [propertyInfo, setPropertyInfo] = useState(initialPropertyInfo);

	const handleSave = async isDraft => {
		if (placeSelected) {
			savePlaceDetails(selectedSuggestion, setPlaceSelected);
		}

		const updatedPropertyInfo = {
			...propertyInfo,
			data: {
				...propertyInfo.data,
				isDraft,
			},
		};

		const result = await addProperty(updatedPropertyInfo);
		if (result.success) {
			console.log('Property added successfully:', true);
		}
	};

	useEffect(() => {
		if (placeSelected) {
			console.log('Place selected updated:', placeSelected);
		}
	}, [placeSelected]);

	useEffect(() => {
		setIsDisabled(selectedTableIndex !== null);
	}, [selectedTableIndex]);

	return (
		<Stack w='100%' gap='30px'>
			<Text fontSize='32px' fontWeight='700' lineHeight='100%'>
				Admin Panel
			</Text>
			<Tabs variant='unstyled' onChange={index => setActiveTab(index)}>
				<Flex justify='space-between' pb='30px'>
					<Button
						variant='secondary'
						fontSize='13px'
						gap='5px'
						onClick={() => navigate(`/${PrivateRoutes.Admin}`)}
					>
						<IconClose boxSize='14px' />
						Cancel
					</Button>

					<TabList
						h='40px'
						display='flex'
						alignItems='center'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.100'
						}
						borderRadius='5px'
						gap='5px'
						p='10px'
					>
						{tabs.map((tab, index) => (
							<TabComponent
								key={index}
								icon={
									<tab.icon
										boxSize='14px'
										color={iconsColor}
									/>
								}
								title={tab.title}
								selectedTableIndex={selectedTableIndex}
								activeTab={activeTab}
								indexTab={index}
							/>
						))}
					</TabList>
					<Flex gap='10px'>
						<Button
							variant={
								isDisabled ? 'secondaryDisabled' : 'secondary'
							}
							fontSize='13px '
							gap='5px'
							onClick={
								isDisabled
									? () => showToast(toast)
									: () => handleSave(true)
							}
						>
							<IconSave boxSize='14px' />
							Save as draft
						</Button>

						<Button
							variant={isDisabled ? 'mainDisabled' : 'main'}
							gap='5px'
							onClick={
								isDisabled
									? () => showToast(toast)
									: () => handleSave(false)
							}
						>
							<IconPublish boxSize='14px' />
							Publish
						</Button>
					</Flex>
				</Flex>
				<TabPanels>
					<TabPanel p='0px'>
						<InformationComponent
							setSelectedSuggestion={setSelectedSuggestion}
							selectedSuggestion={selectedSuggestion}
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<GalleryComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<FinancialDetailsComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<ProjectUpdatesComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
							selectedUpdateIndex={selectedTableIndex}
							setSelectedUpdateIndex={setSelectedTableIndex}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<FAQsComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
							selectedFAQIndex={selectedTableIndex}
							setSelectedFAQIndex={setSelectedTableIndex}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<TimelineComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
							selectedStepIndex={selectedTableIndex}
							setSelectedStepIndex={setSelectedTableIndex}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Stack>
	);
};

export default AddProperty;
