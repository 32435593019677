import { Divider, Flex, GridItem, Text } from '@chakra-ui/react';
import { IconEdit, IconTrash } from '../../../../assets/Icons';

const ProjectUpdatesTableInfo = ({
	update,
	updateIndex,
	last,
	isTablet,
	colorBorder,
	deleteUpdate,
	editUpdate,
}) => {
	return (
		<>
			<GridItem py={isTablet ? '15px' : '0'} px='2px'>
				<Text
					fontSize={isTablet ? '12px' : '14px'}
					fontWeight={isTablet ? '600' : '500'}
				>
					{update.title}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{update.description}
			</Text>

			<Flex
				w='100%'
				minH='50px'
				align='center'
				justify='center'
				gap={isTablet ? '10px' : '25px'}
			>
				<IconEdit
					boxSize='14px'
					cursor='pointer'
					onClick={() => editUpdate(update, updateIndex)}
				/>
				<IconTrash
					boxSize='14px'
					cursor='pointer'
					onClick={() => deleteUpdate(updateIndex)}
				/>
			</Flex>
			{!last && <Divider gridColumn='span 4' borderColor={colorBorder} />}
		</>
	);
};

export default ProjectUpdatesTableInfo;
