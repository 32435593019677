import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerComponent.css';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { IconArrowDown } from '../../assets/Icons';
import { formatMonthDate } from '../../utils/utils';

const DatePickerComponent = ({ name, setInfo }) => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const handleDateChange = dates => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		const dateRange = {
			from: start,
			to: end,
			day: null,
		};

		setInfo(prevInfo => ({
			...prevInfo,
			data: {
				...prevInfo.data,
				[name]: dateRange,
			},
		}));
	};

	const formatRange = (start, end) => {
		if (!start || !end) return '';
		return `${formatMonthDate(start)} - ${formatMonthDate(end)}`;
	};

	const formattedDateRange = formatRange(startDate, endDate);

	return (
		<DatePicker
			selected={startDate}
			startDate={startDate}
			endDate={endDate}
			onChange={handleDateChange}
			customInput={
				<InputGroup>
					<InputRightElement>
						<IconArrowDown />
					</InputRightElement>
					<Input
						name={name}
						placeholder='Select month range'
						value={formattedDateRange}
						readOnly
						cursor='pointer'
					/>
				</InputGroup>
			}
			wrapperClassName='datepicker-wrapper'
			calendarClassName='datepicker-calendar'
			inputClassName='datepicker-input'
			showPopperArrow={false}
			dateFormat='MMMM yyyy'
			selectsRange
			showMonthYearPicker
		/>
	);
};

export default DatePickerComponent;
