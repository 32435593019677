import { Transak } from '@transak/transak-sdk';

const colorMode = 'LIGHT';
const disablePaymentMethods = [
	'gbp_bank_transfer',
	'sep_bank_transfer',
	'pm_open_banking',
	'inr_bank_transfer',
	'inr_upi',
	'sepa_bank_transfer',
	'pm_pse',
	'pm_us_wire_bank_transfer',
	'pm_astropay',
	'pm_pix',
	'pm_gcash',
	'pm_paymaya',
	'pm_bpi',
	'pm_ubp',
	'pm_grabpay',
	'pm_shopeepay',
	'google_pay',
	'apple_pay',
];

export function interactWithTransak({
	purchaseQuantity,
	userInfo = null,
	isSettingsComplete = true,
}) {
	if (!userInfo) {
		isSettingsComplete = false;
	}

	let settings = {
		apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
		environment: Transak.ENVIRONMENTS.STAGING,
		disableWalletAddressForm: true,
		fiatAmount: purchaseQuantity,
		defaultFiatCurrency: 'USD',
		networks: ['arbitrum'],
		cryptoCurrencyList: ['ETH', 'USDC'],
		defaultCryptoCurrency: 'USDC',
		themeColor: '000000',
		backgroundColors: '000000',
		hostURL: window.location.origin,
		widgetHeight: '600px',
		widgetWidth: '400px',
		disablePaymentMethods,
		defaultPaymentMethod: 'credit_debit_card',
		hideExchangeScreen: true,
		hideMenu: true,
		colorMode,
	};

	if (isSettingsComplete) {
		settings = {
			...settings,
			walletAddress: userInfo.walletAddress, // Wallet Address where you want to receive the crypto
			email: userInfo.email,
		};
	}

	const transak = new Transak(settings);

	transak.init();

	// To get all the events
	Transak.on('*', data => {
		console.log(data);
	});

	// This will trigger when the user closed the widget
	Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
		transak.close();
		console.log('Transak SDK closed!');
	});

	/*
	 * This will trigger when the user has confirmed the order
	 * This doesn't guarantee that payment has completed in all scenarios
	 * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
	 */
	Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, orderData => {
		console.log(orderData);
	});

	/*
	 * This will trigger when the user marks payment is made
	 * You can close/navigate away at this event
	 */
	Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, orderData => {
		console.log(orderData);
	});
}
