import {
	Box,
	Grid,
	Stack,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import darkImage from '../assets/upcoming/dark.png';
import lightImage from '../assets/upcoming/light.png';
import { useTranslation } from 'react-i18next';

const UpcomingComponent = ({ title }) => {
	const { colorMode } = useColorMode();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const { t } = useTranslation();

	return (
		<Grid h='100%' w='100%' templateColumns='repeat(12, 1fr)' gap='5px'>
			<Box gridColumn='span 1' />
			<Stack
				gridColumn='span 3'
				h='100%'
				w='100%'
				display='flex'
				justifyContent='center'
				mt='-50px'
			>
				<Text
					variant='colored'
					fontSize={isTablet ? '28px' : '36px'}
					letterSpacing={-0.03}
				>
				
				{t('upcomingTraderComponent.upcoming')}
				
				</Text>
				<Text
					fontWeight='700'
					fontSize={isTablet ? '40px' : '50px'}
					lineHeight='65px'
				>
				
				{t(`upcomingTraderComponent.${title}`)}
				
				</Text>
			</Stack>

			<Box
				gridColumn='span 8'
				backgroundImage={
					colorMode === 'dark'
						? `url(${darkImage})`
						: `url(${lightImage})`
				}
				backgroundPosition='top left'
				backgroundRepeat='no-repeat'
				backgroundSize='cover'
				filter='blur(4px)'
				mr='-30px'
			/>
		</Grid>
	);
};

export default UpcomingComponent;
