import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorMode,
} from '@chakra-ui/react';

import { IconArrowDown } from '../assets/Icons';

const MenuSelectComponent = ({
	w = '100%',
	options,
	fieldName,
	tabInfo = {},
	setTabInfo,
}) => {
	const { colorMode } = useColorMode();

	let selectedOption;
	if (Array.isArray(options)) {
		selectedOption = tabInfo[fieldName] || options[0];
	} else {
		const currentValue = tabInfo[fieldName];

		if (currentValue !== undefined) {
			selectedOption = Object.keys(options).find(
				key => options[key] === currentValue,
			);
		} else {
			selectedOption = Object.keys(options)[0];
		}
	}

	const handleItemClick = elem => {
		setTabInfo(prevState => ({
			...prevState,
			[fieldName]: elem,
		}));
	};

	const handleItemClickObject = key => {
		setTabInfo(prevState => ({
			...prevState,
			[fieldName]: options[key],
		}));
	};

	return (
		<Menu>
			<MenuButton
				h='40px'
				w={w}
				fontWeight='200'
				borderRadius='5px'
				textAlign='start'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				_hover={{
					bg:
						colorMode === 'dark'
							? 'transparencyWhite.200'
							: 'transparencyBlack.200',
				}}
				_active={{
					border: '0.5px solid',
					borderColor:
						colorMode === 'dark' ? 'brand.200' : 'brand.500',
				}}
				rightIcon={<IconArrowDown boxSize='12px' />}
				as={Button}
			>
				<Text fontSize='13px'>{selectedOption}</Text>
			</MenuButton>
			<MenuList fontSize='12px' zIndex={10}>
				{Array.isArray(options)
					? options.map((elem, i) => (
							<MenuItem
								key={i}
								value={elem}
								onClick={() => handleItemClick(elem)}
							>
								{elem}
							</MenuItem>
						))
					: Object.keys(options).map((key, i) => (
							<MenuItem
								key={i}
								value={key}
								onClick={() => handleItemClickObject(key)}
							>
								{key}
							</MenuItem>
						))}
			</MenuList>
		</Menu>
	);
};

export default MenuSelectComponent;
