import { formatEther, JsonRpcProvider } from 'ethers';
import { formatBalanceWallet, oracle } from './walletService';
import { getTokenAmount } from '../utils/utils';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);

export const getEthereumBalance = async walletAddress => {
	const balance = await provider.getBalance(walletAddress);
	return formatEther(balance).toString();
};

export const getEthValueinUSD = async () => {
	const price = await oracle.getLatestPrice('ETH');
	return getTokenAmount(price, 8);
};

export const convertEthereuminUSD = async ethAmount => {
	const ethAmountNumber = parseFloat(ethAmount);
	const ethPrice = await getEthValueinUSD();
	return ethAmountNumber * ethPrice;
};

export const formatEthBalance = async ethAmount => {
	const formattedEthBalance = ethAmount.toFixed(3).toString();
	return formatBalanceWallet(formattedEthBalance);
};
