import { useParams, useNavigate } from 'react-router-dom';
import { projectListData } from '../../data/mockdata';
import { IconBack } from '../../assets/Icons';
import { Flex, Box, Text, Button, useMediaQuery } from '@chakra-ui/react';
import ProjectGeneralInfo from './components/ProjectGeneralInfo';
import SliderPhotos from './components/SliderPhotos';
import GalleryPhoto from './components/GalleryPhoto/GalleryPhoto';
import ProjectMoreInfo from './components/ProjectMoreInfo/ProjectMoreInfo';
import FundingTimeline from './components/FundingTimeline';
import OfferDetails from './components/OfferDetails';
import { PublicRoutes } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';

const ProjectDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();
	const [isMobile] = useMediaQuery('(max-width: 992px)');

	const projectSelected = projectListData.find(
		project => project.id === parseInt(id),
	);

	return (
		<>
			{isMobile ? (
				<SliderPhotos projectSelected={projectSelected} />
			) : (
				<>
					<Flex justify='space-between' pb='15px' align='center'>
						<Button
							variant='secondary'
							onClick={() => navigate(`${PublicRoutes.Home}`)}
						>
							<IconBack boxSize='15px' />

							<Text
								fontWeight='400'
								fontSize='13'
								letterSpacing={0.02}
								pl='5px'
							>
								{t('projectDetails.backDiscovery')}
							</Text>
						</Button>
					</Flex>
					<GalleryPhoto imagesSelected={projectSelected.img} />
				</>
			)}

			<Flex
				alignItems={{ base: 'center', lg: 'start' }}
				flexDirection={{ base: 'column', lg: 'row' }}
				justifyContent='space-between'
				gap={{ base: '30px', lg: '40px', xl: '60px' }}
				pt='30px'
			>
				<Box maxW='704px' w='100%'>
					<ProjectGeneralInfo projectSelected={projectSelected} />
					<ProjectMoreInfo projectSelected={projectSelected} />
				</Box>
				<Flex
					flexDirection='column'
					gap='50px'
					align={{ base: 'center', lg: 'end' }}
				>
					<OfferDetails projectSelected={projectSelected} />
					<FundingTimeline projectSelected={projectSelected} />
				</Flex>
			</Flex>
		</>
	);
};

export default ProjectDetails;
