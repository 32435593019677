import { Contract, JsonRpcProvider } from 'ethers';

import { covertUSDCinUSD, getUSDCBalance } from './usdcService';
import { getUSDTBalance } from './usdtServices';
import { oracleABI } from '../interfaces/oracle.abi';
import { convertEthereuminUSD, getEthereumBalance } from './ethereumServices';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);
const oracleAddress = process.env.REACT_APP_ORACLE_CONTRACT_ADDRESS;
export const oracle = await new Contract(oracleAddress, oracleABI, provider);

const formatBalance = async balance => {
	if (balance !== '0.000') {
		const formattedBalance = parseFloat(balance).toFixed(3);
		return formattedBalance === '0.000' ? '0' : formattedBalance;
	}
};

export const formatBalanceWallet = async balance => {
	return balance === '0.000' ? '0' : balance;
};

export const getWalletBalances = async walletAddress => {
	const [ethBalance, usdtBalance, usdcBalance] = await Promise.all([
		getEthereumBalance(walletAddress),
		getUSDTBalance(walletAddress),
		getUSDCBalance(walletAddress),
	]);

	const [formattedEthBalance, formattedUSDCBalance, formattedUSDTBalance] =
		await Promise.all([
			formatBalance(ethBalance),
			formatBalance(usdcBalance),
			formatBalance(usdtBalance),
		]);

	return { formattedEthBalance, formattedUSDCBalance, formattedUSDTBalance };
};

export const getUSDBalanceInWallet = async balances => {
	const [ethBalance, usdcBalance, usdtBalance] = await Promise.all([
		convertEthereuminUSD(balances.formattedEthBalance),
		covertUSDCinUSD(balances.formattedUSDCBalance),
		covertUSDCinUSD(balances.formattedUSDTBalance),
	]);
	return parseFloat(ethBalance) + usdcBalance + usdtBalance;
};

export const getWalletInfo = async walletAddress => {
	const balances = await getWalletBalances(walletAddress);
	const { formattedEthBalance, formattedUSDCBalance, formattedUSDTBalance } =
		balances;
	const usdBalance = await getUSDBalanceInWallet(walletAddress);
	const ethBalance = formattedEthBalance;
	const usdcBalance = formattedUSDCBalance;
	const usdtBalance = formattedUSDTBalance;
	return {
		ethBalance,
		usdcBalance,
		usdtBalance,
		usdBalance,
	};
};
