import { Box, Tooltip } from '@chakra-ui/react';
import { IconHelp } from '../assets/Icons';

const TooltipComponent = ({ label, placement = 'right' }) => {
	return (
		<Tooltip
			placement={placement}
			label={label}
			sx={{ whiteSpace: 'pre-line' }}
		>
			<Box color='transparencyWhite.500'>
				<IconHelp cursor='pointer' />
			</Box>
		</Tooltip>
	);
};

export default TooltipComponent;
