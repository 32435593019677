import { Contract, JsonRpcProvider } from 'ethers';
import { usdtABI } from '../interfaces/usdtABI';
import { formatBalanceWallet, oracle } from './walletService';
import { getTokenAmount } from '../utils/utils';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);
const contractAddress = process.env.REACT_APP_USDT_CONTRACT_ADDRESS;
export const usdtContract = await new Contract(
	contractAddress,
	usdtABI,
	provider,
);

export const getUSDTBalance = async walletAddress => {
	const balance = await usdtContract.balanceOf(walletAddress);
	return getTokenAmount(balance, 6);
};

const getUSDTValueinUSD = async () => {
	const price = await oracle.getLatestPrice('USDT');
	return getTokenAmount(price, 6);
};

export const convertUSDTinUSD = async usdtAmount => {
	const usdtAmountNumber = parseFloat(usdtAmount);
	const usdtPrice = await getUSDTValueinUSD();
	return usdtAmountNumber * usdtPrice;
};

export const formatBalanceUSDT = async usdtAmount => {
	const formattedUSDTBalance = usdtAmount.toFixed(3).toString();
	return formatBalanceWallet(formattedUSDTBalance);
};
