import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { IconArrowDown } from '../../assets/Icons';

const DatePickerTimelineComponent = ({
	name,
	setEditing,
	setNew,
	selected,
	selectedIndex,
	formattedDate,
}) => {
	const handleDateChange = (date, name) => {
		if (selectedIndex !== null) {
			setEditing(prevState => ({
				...prevState,
				[name]: date,
			}));
		} else {
			setNew(prevState => ({
				...prevState,
				[name]: date,
			}));
		}
	};

	return (
		<DatePicker
			selected={selected}
			onChange={date => handleDateChange(date, name)}
			customInput={
				<InputGroup>
					<InputRightElement>
						<IconArrowDown />
					</InputRightElement>
					<Input
						name={name}
						placeholder='Select date'
						value={formattedDate}
						readOnly
						cursor='pointer'
					/>
				</InputGroup>
			}
			wrapperClassName='datepicker-wrapper'
			calendarClassName='datepicker-calendar'
			inputClassName='datepicker-input'
			showPopperArrow={false}
			dateFormat='MM/dd/yyyy'
		/>
	);
};

export default DatePickerTimelineComponent;
