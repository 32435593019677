import {
	Grid,
	Stack,
	Text,
	useColorMode,
	useColorModeValue,
	useMediaQuery,
} from '@chakra-ui/react';
import AccountBalanceComponent from './components/AccountBalanceComponent';
import CryptoCurrencyComponent from './components/CryptoCurrencyComponent';
import ProjectTokensComponent from './components/ProjectTokenComponent/ProjectTokensComponent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useInfoData from '../../hooks/useInfoData';

const WalletComponent = () => {
	const [hiddenInfo, setHiddenInfo] = useState(false);
	const [isMobile] = useMediaQuery('(max-width: 720px)');
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const { colorMode } = useColorMode();
	const color = useColorModeValue('brand.500', 'brand.200');
	const { t } = useTranslation();
	const user = useSelector(state => state.user);
	const { balances } = useInfoData({ walletAddress: user.walletAddress });

	const handleHideInfo = () => {
		setHiddenInfo(!hiddenInfo);
	};

	return (
		<Stack align='center' gap='30px'>
			<Stack gap='25px'>
				<Text
					gridColumn={!isTablet && 'span 2'}
					fontWeight='700'
					fontSize='32px'
				>
					{t('walletPage.wallet')}
				</Text>
				<Grid
					templateColumns={isTablet ? '1fr' : 'repeat(2, 1fr)'}
					maxW='1080px'
					gap='20px'
				>
					<AccountBalanceComponent
						balance={balances.usdBalance}
						hiddenInfo={hiddenInfo}
						colorMode={colorMode}
						color={color}
						isMobile={isMobile}
					/>
					<CryptoCurrencyComponent
						user={user}
						hiddenInfo={hiddenInfo}
						balances={balances}
						colorMode={colorMode}
						color={color}
						isMobile={isMobile}
						onHideInfo={handleHideInfo}
					/>
				</Grid>
			</Stack>
			<ProjectTokensComponent isTablet={isTablet} isMobile={isMobile} />
		</Stack>
	);
};

export default WalletComponent;
