import { Contract, WebSocketProvider } from 'ethers';
import { usdcABI } from '../interfaces/usdcABI';
import { usdtABI } from '../interfaces/usdtABI';
const useContracts = () => {
	const provider = new WebSocketProvider(process.env.REACT_APP_WEBSOCKET_URL);
	const contractUSDCAddress = process.env.REACT_APP_USDC_CONTRACT_ADDRESS;
	const usdcContract = new Contract(contractUSDCAddress, usdcABI, provider);

	const contractUSDTAddress = process.env.REACT_APP_USDT_CONTRACT_ADDRESS;
	const usdtContract = new Contract(contractUSDTAddress, usdtABI, provider);

	return { usdcContract, usdtContract, provider };
};

export default useContracts;
