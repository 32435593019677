import { Divider, Flex, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import { IconEdit, IconTrash } from '../../../../assets/Icons';
import { formatDateShort } from '../../../../utils/utils';

const TimelineTableInfo = ({
	step,
	stepIndex,
	last,
	isTablet,
	colorBorder,
	deleteStep,
	editStep,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 550px)');
	return (
		<>
			<GridItem py={isTablet ? '15px' : '0'} px='2px'>
				<Text
					fontSize={isTablet ? '12px' : '14px'}
					fontWeight={isTablet ? '600' : '500'}
				>
					{step.title}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{formatDateShort(step.startDate)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{formatDateShort(step.endDate)}
			</Text>
			<Flex
				w='100%'
				minH='50px'
				align='center'
				justify='center'
				gap={isMobile ? '10px' : '25px'}
			>
				<IconEdit
					boxSize='14px'
					cursor='pointer'
					onClick={() => editStep(step, stepIndex)}
				/>
				<IconTrash
					boxSize='14px'
					cursor='pointer'
					onClick={() => deleteStep(stepIndex)}
				/>
			</Flex>
			{!last && <Divider gridColumn='span 4' borderColor={colorBorder} />}
		</>
	);
};

export default TimelineTableInfo;
