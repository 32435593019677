import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { IconClose, IconPlus, IconSave } from '../../../../assets/Icons';
import ProjectUpdatesTable from './ProjectUpdatesTable';
import { useState } from 'react';

/**
 * @name ProjectUpdatesComponent
 * @description This component allows users to add, edit, and manage project updates for a given property. If updates exist, they are displayed in a table. The component manages the state for new and existing updates and handles editing logic.
 * @param {Object} propertyInfo - The information of the property containing the updates.
 * @param {Function} setPropertyInfo - A function to update the property information.
 * @param {number | null} selectedUpdateIndex - The index of the update currently being edited, or null if adding a new update.
 * @param {Function} setSelectedUpdateIndex - A function to set the index of the update being edited.
 * @returns {JSX.Element} The ProjectUpdatesComponent to handle project updates.
 * @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.0.0
 */

const newUpdateInitialValues = { title: '', description: '' };

const ProjectUpdatesComponent = ({
	propertyInfo,
	setPropertyInfo,
	selectedUpdateIndex,
	setSelectedUpdateIndex,
}) => {
	const [newUpdate, setNewUpdate] = useState(newUpdateInitialValues);
	const [editingUpdate, setEditingUpdate] = useState(newUpdateInitialValues);

	const handleInputChange = event => {
		const { name, value } = event.target;

		if (selectedUpdateIndex !== null) {
			setEditingUpdate(prevState => ({
				...prevState,
				[name]: value,
			}));
		} else {
			setNewUpdate(prevState => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const addUpdate = () => {
		if (newUpdate.title && newUpdate.description) {
			setPropertyInfo(prevState => ({
				...prevState,
				updates: [...prevState.updates, newUpdate],
			}));
			setNewUpdate({ title: '', description: '' });
		}
	};
	const editUpdate = (update, updateIndex) => {
		setSelectedUpdateIndex(updateIndex);
		setEditingUpdate(update);
	};

	const saveEditUpdate = () => {
		if (selectedUpdateIndex !== null) {
			setPropertyInfo(prevState => {
				const updatedUpdates = [...prevState.updates];
				updatedUpdates[selectedUpdateIndex] = editingUpdate;

				return {
					...prevState,
					updates: updatedUpdates,
				};
			});

			setEditingUpdate(newUpdateInitialValues);
			setSelectedUpdateIndex(null);
		}
	};

	const cancelEdit = () => {
		setEditingUpdate(newUpdateInitialValues);
		setSelectedUpdateIndex(null);
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Title</FormLabel>
				<Input
					placeholder='Add project update title'
					name='title'
					value={
						selectedUpdateIndex !== null
							? editingUpdate.title
							: newUpdate.title
					}
					onChange={handleInputChange}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Description</FormLabel>
				<Textarea
					rows={5}
					placeholder='Add project update description'
					name='description'
					value={
						selectedUpdateIndex !== null
							? editingUpdate.description
							: newUpdate.description
					}
					onChange={handleInputChange}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				{selectedUpdateIndex !== null ? (
					<Flex gap='10px'>
						<Button
							variant='secondary'
							gap='5px'
							onClick={cancelEdit}
						>
							<IconClose boxSize='13px' />
							<Text fontSize='13px'>Cancel</Text>
						</Button>
						<Button
							variant='main'
							gap='5px'
							onClick={saveEditUpdate}
						>
							<IconSave boxSize='13px' />
							<Text fontSize='13px'>Save edit</Text>
						</Button>
					</Flex>
				) : (
					<Button variant='secondary' gap='5px' onClick={addUpdate}>
						<IconPlus boxSize='13px' mt='-2px' />
						<Text fontSize='13px'>Add update</Text>
					</Button>
				)}
			</Flex>
			{propertyInfo.updates.length > 0 && (
				<Box gridColumn='span 12'>
					<ProjectUpdatesTable
						propertyInfo={propertyInfo}
						setPropertyInfo={setPropertyInfo}
						editUpdate={editUpdate}
					/>
				</Box>
			)}
		</Grid>
	);
};

export default ProjectUpdatesComponent;
