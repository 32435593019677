import {
	Button,
	Divider,
	Flex,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { IconClose, IconWallet } from '../../../../assets/Icons';
import DollarIcon from '../../../../assets/Icons/currency/DollarIcon';
import { useTranslation } from 'react-i18next';


/**
*
    @name WalletComponent
    @description Component to display the balance of a wallet and allows the user to claim tokens.
    @returns {JS.Element} WalletComponent.js
	@author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const WalletComponent = ({ setStep, closeModal }) => {
	const { colorMode } = useColorMode();
	const { t } = useTranslation();

	const handleClick = () => {
		setStep(2);
	};

	return (
		<Stack
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.900'
			}
			w='335px'
			borderRadius='12px'
			p='40px'
			gap='25px'
		>
			<Flex flexDirection='column' textAlign='center' gap='3px'>
				<Text
					color={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
					fontSize='20px'
					fontWeight='700'
				>
					<IconWallet boxSize='18px' mr='7px' />
					{t('dashboard.balance.walletComponent.title')}
				</Text>
				<Text variant='gray' fontSize='14px' fontWeight='bold'>
					0xaSBs...a384A8
				</Text>
				<Divider
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.200'
							: 'transparencyBlack.200'
					}
				/>
			</Flex>
			<Flex flexDirection='column' textAlign='center' gap='4px'>
				<Text fontSize='14px' fontWeight='700'>
				{t('dashboard.balance.walletComponent.claimTokens')}
				</Text>
				<Flex justifyContent='center' gap='7px'>
					<Text variant='gray' fontSize='12px' fontWeight='400'>
						$ELVX
					</Text>
					<Text
						color={colorMode === 'dark' ? 'brand.250' : 'brand.550'}
						fontSize='12px'
						fontWeight='700'
					>
						3.235.492
					</Text>
				</Flex>
				<Text
					variant='colored'
					fontSize='32px'
					fontWeight='700'
					lineHeight='30px'
				>
					$23,200
				</Text>
			</Flex>
			<Flex gap='46px'>
				<Button variant='secondary' onClick={closeModal} p='10px 20px'>
					<IconClose boxSize='14px' />

					<Text fontSize='13px' ml='5px'>
					{t('dashboard.balance.walletComponent.cancel')}
					</Text>
				</Button>
				<Button
					variant='main'
					display='flex'
					p='10px 20px'
					onClick={handleClick}
				>
					<DollarIcon
						boxSize='16px'
						color={colorMode === 'dark' ? 'black' : 'white'}
					/>
					<Text fontSize='13px' ml='5px'>
					{t('dashboard.balance.walletComponent.claim')}
					</Text>
				</Button>
			</Flex>
		</Stack>
	);
};

export default WalletComponent;
